import React, { createContext, FC, ReactNode, useContext } from "react";
import { GetUserSubscriptionResponse } from "../api/services/userSubscriptionService";
import { useSubscriptionQuery } from "../hooks/queries/useSubscriptionQuery";

interface SubscriptionContextType {
  subscriptionResult: GetUserSubscriptionResponse | undefined;
  isSubscriptionLoading: boolean;
  isSubscriptionError: boolean;
  refetchSubscription: () => void;
}

interface SubscriptionProviderProps {
  children: ReactNode;
}

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined
);

export const SubscriptionProvider: FC<SubscriptionProviderProps> = ({
  children,
}) => {
  const {
    subscriptionResult,
    isSubscriptionLoading,
    isSubscriptionError,
    refetchSubscription,
  } = useSubscriptionQuery();

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionResult,
        isSubscriptionLoading,
        isSubscriptionError,
        refetchSubscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

// Custom hook to use SubscriptionContext
export const useSubscriptionContext = (): SubscriptionContextType => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      "useSubscriptionContext must be used within a SubscriptionProvider"
    );
  }
  return context;
};
