import {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  ReactNode,
} from "react";
import {
  onAuthStateChanged,
  signInAnonymously,
  signOut,
  User,
} from "firebase/auth";
import { auth } from "../firebaseConfig";

interface AuthContextType {
  user: User | null;
  isUserVerified: boolean;
  token: string | null;
  refreshUser: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isUserVerified: false,
  token: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshUser: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: async () => {},
});

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        setIsUserVerified(firebaseUser.emailVerified || false);
        fetchToken(firebaseUser);
      } else {
        // No authenticated user, sign in anonymously
        signInAnonymously(auth)
          .then(() => {
            const anonymousUser = auth.currentUser;
            if (anonymousUser) {
              setUser(anonymousUser);
              setIsUserVerified(false);
              fetchToken(anonymousUser);
            }
          })
          .catch((error) => {
            console.error("Error during anonymous sign-in:", error);
          });
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to fetch and set the token
  const fetchToken = async (firebaseUser: User) => {
    try {
      const fetchedToken = await firebaseUser.getIdToken(true);
      setToken(fetchedToken);
    } catch (error) {
      console.error("Error fetching token:", error);
      setToken(null);
    }
  };

  // Refresh user data (including token and verification status)
  const refreshUser = async () => {
    if (auth.currentUser) {
      await auth.currentUser.reload();
      setUser(auth.currentUser);
      setIsUserVerified(auth.currentUser.emailVerified);
      fetchToken(auth.currentUser);
    } else {
      // If no user is logged in, sign in anonymously
      try {
        await signInAnonymously(auth);
        const anonymousUser = auth.currentUser;
        if (anonymousUser) {
          setUser(anonymousUser);
          setIsUserVerified(false);
          fetchToken(anonymousUser);
        }
      } catch (error) {
        console.error("Error during anonymous sign-in:", error);
      }
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setToken(null);
      setIsUserVerified(false);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, isUserVerified, token, refreshUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
