import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { customColors } from "../theme";
import IconTitle from "./IconTitle";
import { QuestionAnswerOutlined } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";

export const FAQsComponent: React.FC = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const FAQs = [];
  for (let i = 1; i < 7; i++) {
    FAQs.push({
      id: "panel" + i,
      question: t("TEXT.FAQ.QUESTION" + i),
      answer: t("TEXT.FAQ.ANSWER" + i),
    });
  }

  return (
    <Box
      sx={{
        padding: { xs: "20px 20px", md: "40px 20px" },
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Grid
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 2fr" }, // 1 column on small screens, 2 columns on medium+
          gap: 1.2,
          alignItems: "top",
        }}
      >
        {/* Left Side */}
        <Grid size={{ xs: 12, md: 1 }} sx={{ paddingRight: "40x" }}>
          <Box display="flex" alignItems="top" sx={{ mb: 2, width: "100%" }}>
            <IconTitle
              title={t("TEXT.FAQ.ICON_TITLE")}
              icon={
                <QuestionAnswerOutlined sx={{ color: customColors.gold }} />
              }
            />
          </Box>
          <Typography
            variant={isMobile ? "h5" : "h3"}
            component="div"
            fontWeight="bold"
            gutterBottom
          >
            {t("TEXT.FAQ.TITLE")}
          </Typography>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            component="div"
            gutterBottom
          >
            {t("TEXT.FAQ.SUB_TITLE")}
          </Typography>
        </Grid>

        {/* Right Side: FAQs */}
        <Grid
          size={{ xs: 12, md: 11 }}
          sx={{
            width: "100%", // Full width on small screens
            // maxWidth: { xs: "100%", md: "600px" }, // Max width on medium+ screens
            margin: "0 auto", // Center it on small screens
            overflow: "hidden", // Prevent overflow issues
          }}
        >
          <Box>
            {FAQs.map((FAQ) => (
              <Accordion
                expanded={expanded === FAQ.id}
                onChange={handleChange(FAQ.id)}
                sx={{
                  color: "white",
                  backgroundColor: customColors.mediumGray,
                  padding: "10px",
                }}
                key={FAQ.id}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: customColors.gold }} /> // Set your desired color here
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  <Typography>{FAQ.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {FAQ.answer.split("\n").map((text, index) => (
                    <Typography key={index}>
                      {text}
                      <br />
                      <br />
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
