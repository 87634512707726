import { useQuery } from "@tanstack/react-query";
import { getUserSubscription } from "../../api/services/userSubscriptionService";
import { useAuthContext } from "../../context/AuthContext";

export const useSubscriptionQuery = () => {
  const { token } = useAuthContext();
  const {
    data: subscriptionResult,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["subscription"],
    queryFn: async () => {
      if (!token) throw new Error("Missing JWT token");

      const headers = { Authorization: `Bearer ${token}` };
      return getUserSubscription(headers);
    },
  });

  //TODO investigate how to fetch after BE updated subsciption
  const refetchWithDelay = () => {
    setTimeout(() => {
      refetch();
    }, 500);
  };

  return {
    subscriptionResult,
    isSubscriptionLoading: isLoading,
    isSubscriptionError: isError,
    refetchSubscription: refetchWithDelay,
  };
};
