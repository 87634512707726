import React from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageIcon from "@mui/icons-material/Image";
import { customColors, goldGradient } from "../theme";
import { useTranslation } from "react-i18next";
import IconTitle from "./IconTitle";
import {
  AutoAwesome,
  DownloadDoneOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  type Step = {
    title: string;
    description: string;
    icon: React.JSX.Element;
    image: string;
  };
  const steps: Step[] = [
    {
      title: t("TEXT.HOW_IT_WORKS.STEP_1_TITLE"),
      description: t("TEXT.HOW_IT_WORKS.STEP_1_DESCRIPTION"),
      icon: <UploadFileOutlined />,
      image:
        "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2Fstep1.jpg?alt=media&token=82b9c5f2-9a3d-413d-8177-0c4751ed3892",
    },
    {
      title: t("TEXT.HOW_IT_WORKS.STEP_2_TITLE"),
      description: t("TEXT.HOW_IT_WORKS.STEP_2_DESCRIPTION"),
      icon: <AutoAwesome />,
      image:
        "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2Fstep2.jpeg?alt=media&token=f0a1612b-9f92-4608-a5b9-47c1a1b3fdeb",
    },
    {
      title: t("TEXT.HOW_IT_WORKS.STEP_3_TITLE"),
      description: t("TEXT.HOW_IT_WORKS.STEP_3_DESCRIPTION"),
      icon: <DownloadDoneOutlined />,
      image:
        "https://firebasestorage.googleapis.com/v0/b/virtual-staging-pro-ai.appspot.com/o/HomePAgeSteps%2Fstep3.jpeg?alt=media&token=fae7270b-36b5-4a3c-b89c-d228cac7592e",
    },
  ];

  return (
    <Box
      sx={{
        padding: { xs: "10px 15px", md: "40px 20px" },
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Grid
        sx={{
          gap: 0,
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconTitle
              title={t("TEXT.HOW_IT_WORKS.ICON_TITLE")}
              centered
              icon={
                <IconButton>
                  <ImageIcon
                    fontSize={"medium"}
                    sx={{ color: customColors.gold }}
                  />
                </IconButton>
              }
            />
          </div>
          <Typography
            variant={isMobile ? "h6" : "h4"}
            component="div"
            gutterBottom
            sx={{ textAlign: "center" }}
          >
            <b>{t("TEXT.HOW_IT_WORKS.TITLE")}</b>{" "}
            {t("TEXT.HOW_IT_WORKS.TITLE2")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }} textAlign={"center"}>
            {t("TEXT.HOW_IT_WORKS.SUB_TITLE")}
          </Typography>
        </Box>

        <Grid container wrap={isMobile ? "wrap" : "nowrap"} spacing={"50px"}>
          {steps.map((step, index) => (
            <Grid
              key={step.title}
              size={{ xs: 12, md: 4 }}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                flexGrow: 1,
                maxWidth: isMobile ? "800px" : "30%",
                backgroundColor: customColors.mediumGray,
                padding: isMobile ? "20px 10px" : "20px",
                borderRadius: "10px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontSize={16}
                    color={customColors.darkGray}
                    sx={{
                      backgroundImage: goldGradient,
                      fontWeight: "700",
                      padding: "0px 8px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  >
                    {index + 1}
                  </Typography>

                  <Typography variant={isMobile ? "h6" : "h6"} component="div">
                    <b>{step.title}</b>
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    mb: isMobile ? 1 : 3,
                    mt: 2,
                    // minHeight: isMobile ? "0px" : "140px",
                  }}
                  component={"div"}
                >
                  {step.description.split("\n").map((text, index) => (
                    <Typography key={index} fontSize={isMobile ? 14 : 16}>
                      {text}
                      <br />
                    </Typography>
                  ))}
                </Typography>

                <img
                  src={step.image}
                  width={isMobile ? "60%" : "90%"}
                  style={{ borderRadius: "10px" }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
