import { cancelPayment } from "../../api/services/cancelPaymentService";
import { useMutation } from "@tanstack/react-query";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/AuthContext";

export const useCancelPaymentMutation = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { refetchSubscription } = useSubscriptionContext();
  const { showSnackbar } = useSnackbarContext();

  const mutation = useMutation<void, Error, void>({
    mutationFn: async () => {
      if (!token) throw new Error("Missing JWT token");
      const headers = { Authorization: `Bearer ${token}` };
      await cancelPayment(headers); // No need to return status
    },
    onSuccess: () => {
      showSnackbar({
        message: t("TEXT.SETTINGS.SUCCESSFUL_CANCELLATION"),
        color: "success",
      });
      refetchSubscription();
    },
    onError: () => {
      showSnackbar({
        message: t("TEXT.SETTINGS.FAILED_CANCELLATION"),
        color: "error",
      });
    },
  });

  return {
    handleCancelPayment: mutation.mutate,
    isCancelPaymentLoading: mutation.isPending,
    isCancelPaymentError: mutation.isError,
    isCancelPaymentSuccess: mutation.isSuccess,
  };
};
