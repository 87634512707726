import { ENDPOINTS } from "../endpoints";

export interface PaymentCustomerEmailParams {
  sessionId: string;
}

export interface PaymentCustomerEmailResponse {
  email_address: string;
}

// Adjusted to accept headers
export const getPaymentCustomerEmail = async ({
  sessionId,
}: PaymentCustomerEmailParams): Promise<PaymentCustomerEmailResponse> => {
  const endpoint = ENDPOINTS.PAYMENT_CUSTOMER_EMAIL;

  try {
    const response = await fetch(`${endpoint}?sessionId=${sessionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get PaymentCustomerEmail");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting PaymentCustomerEmail :", error);
    throw error;
  }
};
