import React, { useMemo, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Tooltip,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { customColors } from "../../../theme";
import { useTranslation } from "react-i18next";
import { LogoutOutlined } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSubscriptionContext } from "../../../context/SubscriptionContext";
import { SubscriptionType } from "../../../api/services/userSubscriptionService";
import { useAuthContext } from "../../../context/AuthContext";

export const Navbar: React.FC = () => {
  const { t } = useTranslation();
  const { user, isUserVerified, logout } = useAuthContext();
  const { subscriptionResult, isSubscriptionLoading, refetchSubscription } =
    useSubscriptionContext();
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)"); // Detect mobile screens
  const isLoggedInUserWithSubscription = isUserVerified && subscriptionResult;

  const menuItems = useMemo(() => {
    let menuItems = [
      { text: t("NAV.HOME"), link: "/" },
      { text: t("NAV.GALLERY"), link: "/gallery" },
      { text: t("NAV.PRICING"), link: "/pricing" },
    ];

    if (isLoggedInUserWithSubscription) {
      if (subscriptionResult.canceled) {
        return [
          { text: t("NAV.HOME"), link: "/" },
          { text: t("NAV.PRICING"), link: "/pricing" },
          { text: t("NAV.MY_PHOTOS"), link: "/myphotos" },
          { text: t("NAV.SETTINGS"), link: "/settings" },
        ];
      }
      if (subscriptionResult.plan === SubscriptionType.FREE) {
        return [
          { text: t("NAV.HOME"), link: "/" },
          { text: t("NAV.GALLERY"), link: "/gallery" },
          { text: t("NAV.PRICING"), link: "/pricing" },
          { text: t("NAV.SETTINGS"), link: "/settings" },
        ];
      } else {
        return [
          { text: t("NAV.HOME"), link: "/" },
          { text: t("NAV.MY_PHOTOS"), link: "/myphotos" },
          { text: t("NAV.PRICING"), link: "/pricing" },
          { text: t("NAV.SETTINGS"), link: "/settings" },
        ];
      }
    }
    return menuItems;
  }, [
    isLoggedInUserWithSubscription,
    subscriptionResult?.canceled,
    subscriptionResult?.plan,
    t,
  ]);

  const renderAuthButtons = () =>
    isSubscriptionLoading ? (
      <></>
    ) : isUserVerified ? (
      isMobile ? (
        <Button
          variant="outlined"
          onClick={logout}
          sx={{
            width: "100%", // Full width on mobile
            textTransform: "none",
            color: customColors.gold,
            borderColor: customColors.gold,
            borderRadius: "25px",
            mb: 1,
          }}
        >
          {t("NAV.LOGOUT")}
        </Button>
      ) : (
        <Tooltip title="Log out">
          <LogoutOutlined
            onClick={logout}
            sx={{
              color: customColors.gold,
              cursor: "pointer",
              marginLeft: "150px",
            }}
          />
        </Tooltip>
      )
    ) : (
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row", // Flex direction based on screen size
          gap: "10px", // Add some gap between buttons
        }}
      >
        <Button
          variant="outlined"
          component={Link}
          to="/login"
          sx={{
            color: customColors.gold,
            borderColor: customColors.gold,
            textTransform: "none",
            borderRadius: "20px",
            padding: "5px 20px",
            "&:hover": {
              borderColor: customColors.white,
              color: customColors.white,
            },
            width: isMobile ? "100%" : "auto", // Full width on mobile
          }}
        >
          {t("NAV.LOGIN")}
        </Button>

        <Button
          variant="contained"
          component={Link}
          to="/signup"
          sx={{
            backgroundColor: customColors.gold,
            textTransform: "none",
            borderRadius: "20px",
            padding: "5px 20px",
            "&:hover": {
              backgroundColor: customColors.white,
              cursor: "pointer",
            },
            width: isMobile ? "100%" : "auto", // Full width on mobile
          }}
        >
          {t("NAV.SIGNUP")}
        </Button>
      </Box>
    );

  const MenuItem = ({ item }: { item: { text: string; link: string } }) => (
    <Button
      component={Link}
      to={item.link}
      color="inherit"
      sx={{
        color: "white",
        fontSize: "16px",
        textTransform: "none",
        "&:hover": { color: customColors.gold },
      }}
    >
      {item.text}
    </Button>
  );

  const MobileDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <Box
        sx={{
          width: 250,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          backgroundColor: customColors.darkGray,
          p: 2,
        }}
        role="presentation"
        onClick={() => setDrawerOpen(false)}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              alignItems: "center",
              color: customColors.gold,
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            Virtual Staging&nbsp;
            <span style={{ color: "#B3B3B3" }}>Ai</span>
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography
            sx={{
              fontSize: "11px",
              color: customColors.silver,
              textAlign: "center",
              mb: "20px",
            }}
          >
            {isUserVerified ? user?.email : ""}
          </Typography>

          <List>
            {menuItems.map((item, index) => (
              <ListItemButton component={Link} to={item.link} key={index}>
                <ListItemText primary={item.text} sx={{ fontWeight: "bold" }} />
              </ListItemButton>
            ))}
          </List>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Divider sx={{ my: 2 }} />
          {renderAuthButtons()}
        </Box>
      </Box>
    </Drawer>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: customColors.darkGray,
        margin: "0",
        boxShadow: "none",
        padding: "10px 0",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <span style={{ fontWeight: "bold" }}>Virtual Staging</span> &nbsp;
          <span style={{ fontWeight: "bold", color: customColors.gold }}>
            PRO
          </span>{" "}
          &nbsp;
          <span style={{ fontWeight: "lighter", color: "#B3B3B3" }}>Ai</span>
        </Typography>

        {!isMobile && (
          <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
            {menuItems.map((item, index) => (
              <MenuItem item={item} key={index} />
            ))}
          </Box>
        )}

        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon sx={{ color: customColors.gold }} />
            </IconButton>
            <MobileDrawer />
          </>
        ) : (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {renderAuthButtons()}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
