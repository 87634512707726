import { useState } from "react";
import { createPayment, PaymentParams } from "../api/services/paymentService";
import { useAuthContext } from "../context/AuthContext";

export const usePayment = () => {
  const { token } = useAuthContext();
  const [redirectURL, setRedirectURL] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleGetPayment = async (params: PaymentParams) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const result = await createPayment(params, headers);

      // Set success state and store payment_id from the response
      setIsSuccess(true);
      setRedirectURL(result.redirect_uri);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetPayment,
    redirectURL,
    isLoading,
    isError,
    isSuccess,
  };
};
