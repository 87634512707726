import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface Props {
  condition: boolean;
  redirectTo?: string;
}

export const ConditionalRoute: FC<Props> = ({
  condition,
  redirectTo = "/",
}) => {
  if (!condition) {
    console.warn("Unauthorized access, redirecting to:", redirectTo);
  }
  return condition ? <Outlet /> : <Navigate to={redirectTo} />;
};
