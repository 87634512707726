import styled from "@emotion/styled";
import {
  Card,
  Box,
  Typography,
  Divider,
  Button,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customColors } from "../../theme";
import {
  AttachMoneyOutlined,
  CancelOutlined,
  UpgradeSharp,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "../Loader";
import { ErrorComponent } from "../ErrorComponent";
import { SubscriptionType } from "../../api/services/userSubscriptionService";
import { useManagePayment } from "../../hooks/useMangePayment";
import { usePaymentHistory } from "../../hooks/usePaymentHistory";
import { PaymentHistory } from "./PaymentHistory";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useCancelPaymentMutation } from "../../hooks/mutations/useCancelPaymentMutation";

const BillingCard = styled(Card)(({ theme }) => ({
  padding: "20px",
  borderRadius: "12px",
  marginTop: "20px",
  backgroundColor: customColors.semiDarkGray,
  color: "#fff",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  border: "1px solid " + customColors.lightGray,
}));

const ProgressWrapper = styled(Box)(({ theme }) => ({
  marginTop: "10px",
  marginBottom: "10px",
}));

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const navigate = useNavigate();
  const managePaymentAPi = useManagePayment();
  const { handleCancelPayment, isCancelPaymentLoading } =
    useCancelPaymentMutation();
  const { subscriptionResult, isSubscriptionLoading, isSubscriptionError } =
    useSubscriptionContext();
  const { paymentHistoryResult } = usePaymentHistory();

  const {
    plan,
    cost,
    next_payment_date,
    used_credits_per_month,
    total_credits_per_month,
    canceled,
  } = subscriptionResult || {};

  // TODO change this
  useEffect(() => {
    if (
      !managePaymentAPi.isLoading &&
      !managePaymentAPi.isError &&
      managePaymentAPi.redirectURL
    ) {
      window.location.href = managePaymentAPi.redirectURL;
      // window.open(paymentAPI.redirectURL);
    }
  }, [managePaymentAPi.redirectURL]);

  // TODO add onSuccess dialog instead of snackbar
  const handleCancelSubscription = async () => {
    setOpenCancelDialog(false);
    handleCancelPayment();
  };

  const isFreePlan = plan === SubscriptionType.FREE;

  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cost && (!next_payment_date || !canceled) ? cost : 0); // TODO remove !canceled once BE fixes next_payment_date

  return isSubscriptionLoading ||
    isCancelPaymentLoading ||
    managePaymentAPi.isLoading ||
    managePaymentAPi.redirectURL ? (
    <Loader />
  ) : isSubscriptionError ? (
    <ErrorComponent />
  ) : (
    <Box p={4} maxWidth={1400} mx="auto">
      <Typography variant="h4" gutterBottom>
        {t("TEXT.SETTINGS.TITLE")}
      </Typography>

      <BillingCard>
        <Typography variant="h6">{t("TEXT.SETTINGS.BILLING_TITLE")}</Typography>
        <Typography variant="body2" gutterBottom>
          {t("TEXT.SETTINGS.BILLING_DESCRIPTION")}
        </Typography>

        <Divider sx={{ marginY: 2, backgroundColor: customColors.lightGray }} />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">
              {t("TEXT.SETTINGS.NEXT_PAYMENT")}{" "}
              <strong>{formattedPrice}</strong>
            </Typography>
            {next_payment_date && !canceled && !isFreePlan ? (
              <Typography variant="body1">
                {t("TEXT.SETTINGS.NEXT_PAYMENT_DATE")}{" "}
                <strong>
                  {dayjs(next_payment_date).format("MMM D, YYYY")}
                </strong>
              </Typography>
            ) : null}
          </Box>
          {!isFreePlan || canceled ? (
            <Button
              variant="outlined"
              startIcon={<AttachMoneyOutlined />}
              onClick={() => managePaymentAPi.handleManagePayment()}
            >
              {t("TEXT.SETTINGS.MANAGE_PAYMENT")}
            </Button>
          ) : null}
        </Box>
      </BillingCard>

      <BillingCard>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="8px"
        >
          <Box display="flex" pr="8px" gap="8px" flexWrap="wrap" rowGap="8px">
            <Typography variant="h6">
              {t("TEXT.SETTINGS.PLAN." + plan)} {t("TEXT.SETTINGS.PLAN_TITLE")}
            </Typography>
            {canceled ? (
              <Button
                variant="outlined"
                color="error"
                sx={{
                  pointerEvents: "none",
                }}
              >
                {t("TEXT.SETTINGS.CANCELLED")}
              </Button>
            ) : null}
          </Box>
          <Box
            flexDirection={{ xs: "column", sm: "row" }}
            display="flex"
            flexWrap="wrap"
            gap="8px"
            rowGap="8px"
          >
            {!isFreePlan && !canceled ? (
              <Button
                variant="outlined"
                color="error"
                startIcon={<CancelOutlined />}
                onClick={() => setOpenCancelDialog(true)}
              >
                {t("TEXT.SETTINGS.CANCEL")}
              </Button>
            ) : null}
            <Button
              variant="outlined"
              onClick={() => navigate(`/pricing`)}
              startIcon={<UpgradeSharp />}
            >
              {canceled
                ? t("TEXT.SETTINGS.RENEW")
                : isFreePlan
                  ? t("TEXT.SETTINGS.SUBSCRIBE_NOW")
                  : t("TEXT.SETTINGS.UPGRADE")}
            </Button>
          </Box>
        </Box>

        {isFreePlan ? null : (
          <>
            <Divider
              sx={{ marginY: 2, backgroundColor: customColors.lightGray }}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  📸
                </Box>
                {t("TEXT.SETTINGS.PHOTO_LIMIT", {
                  total: total_credits_per_month,
                })}
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  🎨
                </Box>
                {t("TEXT.SETTINGS.ALL_ROOM_TYPES")}
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  🪑
                </Box>
                {t("TEXT.SETTINGS.FURNITURE_REMOVAL")}
              </Typography>

              <Typography
                variant="body2"
                display="flex"
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  🚀
                </Box>
                {t("TEXT.SETTINGS.UNLIMITED_RENDERS")}
              </Typography>

              <Typography variant="body2" display="flex" alignItems="center">
                <Box
                  component="span"
                  sx={{ display: "inline-flex", alignItems: "center", mr: 1 }}
                >
                  ❌
                </Box>
                {t("TEXT.SETTINGS.NO_WATERMARKS")}
              </Typography>
            </Box>

            <>
              <Divider
                sx={{ marginY: 2, backgroundColor: customColors.lightGray }}
              />

              <ProgressWrapper>
                <Typography variant="body2">
                  {t("TEXT.SETTINGS.USED_PHOTOS", {
                    used: used_credits_per_month,
                    total: total_credits_per_month,
                  })}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={
                    ((used_credits_per_month ?? 0) /
                      (total_credits_per_month ?? 1)) *
                    100
                  }
                  sx={{
                    height: 16,
                    borderRadius: 5,
                    mt: "20px",
                    backgroundColor: customColors.lightGray,
                  }}
                />
              </ProgressWrapper>

              {!next_payment_date || canceled ? null : (
                <Typography variant="body2" align="right">
                  {t("TEXT.SETTINGS.IMAGE_CREDITS_REFILL", {
                    date: dayjs(next_payment_date).format("MMMM D, YYYY"),
                  })}
                </Typography>
              )}
            </>
          </>
        )}
      </BillingCard>
      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#333",
          },
        }}
      >
        <DialogTitle>{t("TEXT.SETTINGS.CONFIRM_CANCEL_TITLE")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#fff" }}>
            {t("TEXT.SETTINGS.CONFIRM_CANCEL_MESSAGE")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)} color="primary">
            {t("TEXT.SETTINGS.CANCEL_NO")}
          </Button>
          <Button
            onClick={handleCancelSubscription}
            color="error"
            autoFocus
            disabled={isCancelPaymentLoading} // Disable while loading
          >
            {t("TEXT.SETTINGS.CANCEL_YES")}
          </Button>
        </DialogActions>
      </Dialog>
      <BillingCard>
        <PaymentHistory payments={paymentHistoryResult} />
      </BillingCard>
    </Box>
  );
};

export default Settings;
