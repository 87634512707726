import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Reviews as ReviewsIcon } from "@mui/icons-material";
import { Typography, useMediaQuery } from "@mui/material";
import "./ReviewSlider.css";
import { customColors } from "../../../theme";
import IconTitle from "../../IconTitle";
import { reviews } from "./ReviewContent";
import { useTranslation } from "react-i18next";

export const ReviewSlider: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  return (
    <div
      style={{
        maxWidth: "1400px",
        margin: "0 auto",
        color: "#fff",
        position: "relative",
        overflow: "visible",
        marginTop: "20px",
      }}
    >
      {/* Title with MUI Icon and Subtitle */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <IconTitle
          title={"Reviews"}
          icon={
            <ReviewsIcon
              style={{
                color: customColors.gold,
                fontSize: "16px",
                marginRight: "10px",
              }}
            />
          }
        />
      </div>
      <Typography
        variant="h4"
        style={{ textAlign: "center", marginBottom: "30px" }}
      >
        {t("TEXT.REVIEWS_TITLE")}
      </Typography>

      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={3} // Default for large screens
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          // when window width is <= 640px (mobile)
          0: {
            slidesPerView: 1, // Show 1 card on small screens
            spaceBetween: 20,
          },
          // when window width is <= 768px (tablet)
          640: {
            slidesPerView: 2, // Show 2 cards on tablet-sized screens
            spaceBetween: 30,
          },
          // when window width is >= 1024px (desktop)
          1024: {
            slidesPerView: 3, // Show 3 cards on larger screens
            spaceBetween: 50,
          },
        }}
        style={{
          // paddingBottom: "10px",
          paddingBottom: "40px",
          paddingLeft: isMobile ? "16px" : "50px",
          paddingRight: isMobile ? "16px" : "50px",
        }} // Ensure pagination dots are visible
      >
        {reviews.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                backgroundColor: customColors.mediumGray,
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                color: "#fff",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between", // Ensure top and bottom spacing
                // border: "3px solid red",
              }}
            >
              {/* Review Content */}
              <div style={{ flexGrow: 1 }}>
                {/* Star Rating */}
                <div style={{ color: customColors.gold, fontSize: "20px" }}>
                  {"★".repeat(testimonial.stars)}
                </div>
                {/* Review Text */}
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "1.5",
                    color: "#fff",
                    margin: "20px 0",
                  }}
                >
                  {testimonial.review}
                </p>
              </div>

              {/* Bottom Section: Profile and Logo */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  marginTop: "20px",
                }}
              >
                {/* Profile Section */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* User Image */}
                  {testimonial.image && (
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <div>
                    {/* User Info */}
                    <h4
                      style={{ margin: 0, fontWeight: "bold", color: "#fff" }}
                    >
                      {testimonial.name}
                    </h4>
                    <p style={{ margin: "5px 0", color: "#bbb" }}>
                      {testimonial.location}
                    </p>
                  </div>
                </div>

                {/* Company Logo */}
                {testimonial.companyLogo && (
                  <img
                    src={testimonial.companyLogo}
                    alt={testimonial.company}
                    style={{ width: "60px", marginLeft: "10px" }} // Added margin to avoid overlap with the profile
                  />
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
