import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Link,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Google as GoogleIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { auth, googleProvider } from "../../firebaseConfig";
import { CustomButton } from "../CustomButton";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { usePaymentCustomerEmail } from "../../hooks/usePaymenCustomerEmail";
import { Loader } from "../Loader";

import PasswordField from "./PasswordField";

export const Register: React.FC = () => {
  const { t } = useTranslation();
  const { refetchSubscription } = useSubscriptionContext();
  const { handleGetPaymentCustomerEmail, paymentEmail, isLoading, isError } =
    usePaymentCustomerEmail();

  const navigate = useNavigate();

  // Form state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  // Validation state
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get("session_id");
  const isCommingFromPayment = sessionId && sessionId?.length > 0 && !isError;

  // Enable button if everything is valid
  const isFormValid =
    (email || paymentEmail) &&
    password &&
    isEmailValid &&
    isPasswordValid &&
    termsAccepted;

  useEffect(() => {
    if (sessionId) {
      handleGetPaymentCustomerEmail({ sessionId });
    }
  }, [sessionId]);

  // Handle form submit for registration
  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage(""); // Reset error message

    if (!isFormValid) return;

    try {
      // Firebase register logic
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        isCommingFromPayment ? paymentEmail : email,
        password
      );
      await sendEmailVerification(userCredential.user);

      navigate(`/check-your-email`);
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        setErrorMessage(t("TEXT.AUTH.EMAIL_IN_USE"));
      } else {
        setErrorMessage(t("TEXT.AUTH.REGISTRATION_FAILED"));
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, googleProvider);
      refetchSubscription();
      navigate("/");
    } catch (error) {
      console.error("Google Sign-In error", error);
    } finally {
      setLoading(false);
    }
  };

  // Validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  };

  // Validate password (at least 8 characters and contains a number and a letter)
  const validatePassword = (password: string) => {
    const isStrong =
      password.length >= 8 && /\d/.test(password) && /[a-zA-Z]/.test(password);
    setIsPasswordValid(isStrong);
  };

  // Handle email input change
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  // Handle password input change
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // minHeight: "100vh",
        p: { xs: "20px", md: "60px" },
        m: "20px",
      }}
    >
      <Typography component="h1" variant="h5">
        {t("TEXT.AUTH.CREATE_ACCOUNT_TITLE")}
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 1, mb: 2 }}>
        {t("TEXT.AUTH.CREATE_ACCOUNT_SUBTITLE")}
      </Typography>
      {errorMessage && (
        <Typography color="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Typography>
      )}
      {isCommingFromPayment && isLoading ? (
        <Loader />
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: "100%", maxWidth: 400 }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t("TEXT.AUTH.EMAIL")}
            value={isCommingFromPayment ? paymentEmail : email}
            disabled={!!isCommingFromPayment}
            onChange={handleEmailChange}
            error={!isEmailValid}
            helperText={!isEmailValid ? t("TEXT.AUTH.INVALID_EMAIL") : ""}
            autoComplete="email"
            autoFocus
            sx={{
              input: {
                color: "white",
                WebkitTextFillColor: "white",
              },
              "& .MuiInputLabel-root": {
                color: "white", // Default label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "white", // Focused label color
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: "white", // Disabled label color
              },
              "& .MuiInputLabel-shrink": {
                color: "white", // Shrinked label color (when input has value)
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
                "&.Mui-disabled": {
                  "& input": {
                    color: "white",
                    WebkitTextFillColor: "white",
                  },
                  "& fieldset": {
                    borderColor: "white",
                  },
                },
              },
            }}
          />
          <PasswordField
            password={password}
            handlePasswordChange={handlePasswordChange}
            isPasswordValid={isPasswordValid}
            label={t("TEXT.AUTH.PASSWORD")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                {t("TEXT.AUTH.I_HAVE_AGREE")}
                <Link href="/terms-of-service" variant="body2">
                  {t("TEXT.AUTH.TERMS_OF_SERVICE")}
                </Link>
              </Typography>
            }
          />

          <CustomButton
            text={t("TEXT.AUTH.CREATE_ACCOUNT")}
            isDisabled={!isFormValid || loading}
            isLoading={loading}
            onClick={handleSubmit}
          />
          {!isCommingFromPayment && (
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              disabled={loading} // Disable Google Sign-In button during loading
              sx={{ mb: 2 }}
            >
              {t("TEXT.AUTH.CONTINUE_WITH_GOOGLE")}
            </Button>
          )}
          <Typography variant="body2" align="center">
            {t("TEXT.AUTH.ALREADY_HAVE_ACCOUNT")}{" "}
            <Link href="#" variant="body2" onClick={() => navigate("/login")}>
              {t("TEXT.AUTH.LOG_IN")}
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
