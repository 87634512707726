import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useTranslation } from "react-i18next";
import { useNewsletter } from "../../hooks/useNewsletter";
import { CustomButton } from "../CustomButton";

export const Footer: React.FC = () => {
  const newsletter = useNewsletter();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsSubmitted(false);
    setIsSent(false);
  };

  const handleNewsletterSubmit = () => {
    setIsSubmitted(true);
    if (validateEmail(email)) {
      newsletter.handlePutNewsletter({ mail: email });
      setIsSent(true);
      setEmail("");
    }
  };

  return (
    <footer
      style={{
        backgroundColor: "#333",
        color: "#fff",
        display: "flex",
        justifyContent: "space-around",
        padding: "40px",
        textAlign: "center",
        flexWrap: "wrap",
      }}
    >
      <div style={{ flex: 1, margin: "0 20px", textAlign: "center" }}>
        <h3
          style={{
            letterSpacing: "8px",
            marginBottom: "40px",
            fontSize: "18px",
          }}
        >
          {t("FOOTER.LINKS").toUpperCase()}
        </h3>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          <li style={{ marginBottom: "15px" }}>
            <a
              href="/terms-of-use"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {t("FOOTER.TERMS")}
            </a>
          </li>
          <li style={{ marginBottom: "15px" }}>
            <a
              href="/privacy-policy"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {t("FOOTER.PRIVACY")}
            </a>
          </li>
          <li>
            <a
              href="/privacy-policy"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {t("FOOTER.COOKIE")}
            </a>
          </li>
        </ul>
      </div>

      <div style={{ flex: 1, margin: "0 20px", textAlign: "center" }}>
        <h3
          style={{
            letterSpacing: "8px",
            marginBottom: "40px",
            fontSize: "18px",
          }}
        >
          {t("FOOTER.NEWSLETTER").toUpperCase()}
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleNewsletterSubmit();
          }}
          style={{ display: "inline-block" }}
        >
          <input
            type="email"
            placeholder="Your Email Address"
            style={{
              maxWidth: "315px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginBottom: "6px",
            }}
            value={email}
            onChange={handleInputChange}
          />
          <div
            style={{
              minHeight: "15px",
              marginBottom: "6px",
              marginTop: "6px",
              fontSize: "12px",
              color:
                isSubmitted && !isSent && !validateEmail(email)
                  ? "red"
                  : isSent && newsletter.isSuccess
                    ? "green"
                    : "transparent",
              transition: "color 0.3s",
            }}
          >
            {isSubmitted &&
              !isSent &&
              !validateEmail(email) &&
              "Please enter a valid email."}
            {isSent && "Subscribed successfully"}
          </div>
          <CustomButton
            text={t("FOOTER.SUBSCRIBE_NOW")}
            onClick={handleNewsletterSubmit}
            isLoading={newsletter.isLoading}
            sx={{ borderRadius: "24px", width: "170px", padding: "10px 20px" }}
          />
        </form>
      </div>

      <div style={{ flex: 1, margin: "0 20px", textAlign: "center" }}>
        <h3
          style={{
            letterSpacing: "8px",
            marginBottom: "40px",
            fontSize: "18px",
          }}
        >
          {t("FOOTER.CONTACT").toUpperCase()}
        </h3>
        <p style={{ margin: 0, lineHeight: 1.8 }}>
          contact@byteopia-it.com
          <br />
          Building A1, Dubai Digital Park
          <br />
          Dubai Silicon Oasis | Dubai, UAE
        </p>
        <div style={{ marginTop: "15px" }}>
          <a
            href="https://www.instagram.com/virtualstaging.pro?igsh=bG94eGtocXlxbA=="
            style={{ color: "#fff", fontSize: "20px", textDecoration: "none" }}
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};
