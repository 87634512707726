import { ENDPOINTS } from "../endpoints";

interface OrderParams {
  image: File; // The image file
  removeFurniture: boolean;
  spaceName: string;
  styleId: string;
  widgetId: string;
  demoId: string | null;
}

export type PostOrderResponse = {
  order_id: string;
};

// Adjusted to accept headers
export const createOrder = async (
  { image, removeFurniture, spaceName, styleId, widgetId, demoId }: OrderParams,
  headers: { Authorization: string }
): Promise<PostOrderResponse> => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("remove_furniture", removeFurniture.toString());
  formData.append("space_name", spaceName);
  formData.append("style_id", styleId);
  formData.append("widget_id", widgetId);
  if (demoId !== null) {
    formData.append("demo_id", demoId);
  }

  try {
    const response = await fetch(ENDPOINTS.ORDER, {
      method: "POST",
      headers: {
        Authorization: headers.Authorization,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to create order");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const data: PostOrderResponse = await response.json();

    return data; // Now it returns the parsed PostOrderResponse object
  } catch (error) {
    console.error("Error creating order:", error);
    throw error;
  }
};
