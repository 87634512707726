import { useState } from "react";
import {
  createOrderGenerateMore,
  OrderGenerateMoreParams,
} from "../api/services/orderGenerateMoreService";
import { useAuthContext } from "../context/AuthContext";

export const usePostOrderGenerateMore = () => {
  const { token } = useAuthContext();
  const [orderSuccess, setOrderSuccess] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleGetOrderGenerateMore = async (
    params: OrderGenerateMoreParams
  ) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const generateMorePostedSuccesfully = await createOrderGenerateMore(
        params,
        headers
      );

      // Set success state and store order_id from the response
      setIsSuccess(true);
      setOrderSuccess(generateMorePostedSuccesfully);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetOrderGenerateMore,
    orderSuccess,
    isLoading,
    isError,
    isSuccess,
  };
};
