import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

export const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Container
      maxWidth="md"
      sx={{ padding: isMobile ? "20px" : "60px", mb: "60px" }}
    >
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.TITLE")}
        </Typography>

        <Typography variant="body1" sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.INTRODUCTION")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.INFORMATION_COLLECTION")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.INFORMATION_COLLECTION_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.LOG_DATA")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.LOG_DATA_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.COOKIES")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.COOKIES_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.SERVICE_PROVIDERS")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.SERVICE_PROVIDERS_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.SECURITY")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.SECURITY_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.LINKS_TO_OTHER_SITES")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.LINKS_TO_OTHER_SITES_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.CHILDREN_PRIVACY")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.CHILDREN_PRIVACY_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.CHANGES_TO_POLICY")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.CHANGES_TO_POLICY_PARAGRAPH")}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ marginTop: "40px" }}>
          {t("DOCUMENTS.PRIVACY_POLICY.CONTACT_US")}
        </Typography>
        <Typography variant="body1">
          {t("DOCUMENTS.PRIVACY_POLICY.CONTACT_US_PARAGRAPH")}
        </Typography>
      </Box>
    </Container>
  );
};
