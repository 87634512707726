import React from "react";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImageIcon from "@mui/icons-material/Image";
import { customColors, gradientBackgroundImage } from "../../theme";
import { useTranslation } from "react-i18next";
import { beforeAndAftersGallery } from "./imageLinks";
import IconTitle from "../IconTitle";
import BeforeAfterGallery from "./BeforeAfterGallery";

export const Gallery: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Box
      sx={{
        backgroundImage: gradientBackgroundImage,
        width: "100%",
        height: "100%",
        margin: "0",
      }}
    >
      <Box
        sx={{
          padding: { xs: "20px 20px", md: "40px 20px" },
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <Grid
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: 0,
            alignItems: "center",
          }}
        >
          <Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ mb: 2, width: "100%" }}
            >
              <IconTitle
                title={t("NAV.GALLERY")}
                icon={
                  <IconButton>
                    <ImageIcon
                      fontSize={"medium"}
                      sx={{ color: customColors.gold }}
                    />
                  </IconButton>
                }
              />
            </Box>
            <Typography
              variant={isMobile ? "h5" : "h3"}
              component="div"
              gutterBottom
            >
              <b>{t("TEXT.GALLERY_TITLE")}</b> {t("TEXT.GALLERY_SUBTITLE")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              {t("TEXT.GALLERY_DESCRIPTION")}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              overflow: "hidden",
            }}
          >
            <BeforeAfterGallery
              beforeAndAfterPicturesData={beforeAndAftersGallery}
              maxWidth="888px"
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
