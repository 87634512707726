import React, { useCallback, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { customColors } from "../../theme";
import { FormPanel } from "./UploadPhotoFormPanel";
import { ImagePanel } from "./UploadPhotoImagePanel";
import { Loader } from "../Loader";
import { ErrorComponent } from "../ErrorComponent";

import { useCurrentImageContext } from "../../context/CurrentImageContext";
import { useCreateOrderMutation } from "../../hooks/mutations/useCreateOrderMutation";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import imageCompression from "browser-image-compression";
import { SimpleImageData } from "../Gallery/imageLinks";

interface Props {
  onPhotoSubmited?: (orderId: string) => void;
}

export const UploadPhoto: React.FC<Props> = ({ onPhotoSubmited }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { handleCreateOrder, isOrderSuccess, isOrderError, orderId } =
    useCreateOrderMutation();

  const [orderSent, setOrderSent] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isCompressing, setIsCompressing] = useState<boolean>(false);

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [demoId, setDemoId] = useState<string | null>(null);
  const { setCurrentImage } = useCurrentImageContext();

  const navigate = useNavigate();

  if (isOrderSuccess && orderId) {
    if (onPhotoSubmited) {
      onPhotoSubmited(orderId);
    } else {
      const params = new URLSearchParams();
      params.append(QUERY_PARAMS.order, orderId);
      navigate(`/photo?${params.toString()}`);
    }
  }

  // Handle file upload from device
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setDemoId(null);
      setIsCompressing(true); // Set loading to true when image compression starts

      const options = {
        maxSizeMB: 1, // Maximum file size in MB
        maxWidthOrHeight: 1920, // Resize the image to fit within this dimension
        useWebWorker: true, // Use Web Workers for better performance
        fileType: "image/webp", // Convert to WebP format
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setSelectedImage(compressedFile);
        setCurrentImage(compressedFile);
      } catch (error) {
        console.error("Error during image compression:", error);
      } finally {
        setIsCompressing(false); // Set loading to false once compression finishes
      }
    }
  };

  // Handle image selection from example images
  const handleImageSelect = async (image: SimpleImageData) => {
    try {
      setIsCompressing(true); // Indicate processing

      // Fetch the sample image as a blob
      const response = await fetch(image.url);
      const blob = await response.blob();

      // Convert the blob into a File object
      const file = new File([blob], `${image.id}.jpg`, {
        type: "image/jpeg",
      });

      // Update state with the selected image
      setSelectedImage(file);
      setCurrentImage(file);
      setDemoId(image.id); // Mark it as a demo image
    } catch (error) {
      console.error("Error selecting demo image:", error);
    } finally {
      setIsCompressing(false);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setDemoId(null);
    setCurrentImage(null);
  };

  // "65b4e95ec2b80c47dfe933ab"
  const createOrderHandler = useCallback(
    (
      removeFurniture: boolean,
      spaceName: string,
      styleId: string,
      widgetId?: string
    ) => {
      setOrderSent(true);
      selectedImage &&
        handleCreateOrder({
          image: selectedImage,
          removeFurniture,
          spaceName,
          styleId,
          widgetId: widgetId || "65b4e95ec2b80c47dfe933ab",
          demoId,
        });
    },
    [demoId, handleCreateOrder, selectedImage]
  );

  const imagePanel = ImagePanel({
    selectedImage,
    handleRemoveImage,
    handleImageSelect,
    handleFileUpload,
    isCompressing,
  });

  const formPanel = (
    <Grid size={{ xs: 12, md: 4 }}>
      {" "}
      {FormPanel({
        handleCreateOrder: createOrderHandler,
        isDisabled: !selectedImage,
        // onSubmit: () => setOrderSent(true),
        setIsError,
      })}{" "}
    </Grid>
  );

  return (
    <Box
      sx={{
        p: isMobile ? 2 : 4,
        backgroundColor: customColors.semiDarkGray,
        color: "#fff",
        borderRadius: "10px",
        maxWidth: "1200px",
        minHeight: isMobile ? "300px" : "500px",
        margin: "0 auto",
        marginBottom: "40px",
      }}
    >
      {orderSent ? (
        <Loader />
      ) : isError || isOrderError ? (
        <ErrorComponent />
      ) : (
        <Grid container spacing={isMobile ? 0 : 4}>
          {isMobile ? (
            <>
              {imagePanel} {formPanel}
            </>
          ) : (
            <>
              {formPanel}
              {imagePanel}{" "}
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};
