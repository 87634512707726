import React from "react";
import { Container, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

export const FeaturesSection: React.FC = () => {
  const { t } = useTranslation();

  const features = [
    { label: t("TEXT.FEATURES.CHEAPER") },
    { label: t("TEXT.FEATURES.INSTANT_RESULTS") },
    { label: t("TEXT.FEATURES.FURNITURE_REMOVAL") },
    { label: t("TEXT.FEATURES.DESIGN_STYLES") },
    { label: t("TEXT.FEATURES.USERS") },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        color: "white",
        padding: { xs: "20px 0 20px 0", md: "20px" },
      }}
    >
      <Container>
        <Grid
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(5, 1fr)",
            },
            gap: {
              xs: 1,
              sm: 4,
              md: 6,
            },
            justifyContent: "center",
          }}
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "center",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <CheckIcon color="primary" sx={{ mr: 1 }} />
              <Typography
                variant="subtitle1"
                align="left"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                {feature.label}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
