import { useState } from "react";
import {
  getPaymentCustomerEmail,
  PaymentCustomerEmailParams,
} from "../api/services/paymentCustomerEmailService";
import { useAuthContext } from "../context/AuthContext";

export const usePaymentCustomerEmail = () => {
  const { token } = useAuthContext();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleGetPaymentCustomerEmail = async (
    params: PaymentCustomerEmailParams
  ) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      // Pass headers to the API call and get the response
      const result = await getPaymentCustomerEmail(params);

      // Set success state and store PaymentCustomerEmail_id from the response
      setIsSuccess(true);
      setEmail(result.email_address);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleGetPaymentCustomerEmail,
    paymentEmail: email,
    isLoading,
    isError,
    isSuccess,
  };
};
