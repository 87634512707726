import React, { useEffect, useMemo } from "react";
import { Container } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Home } from "./components/Home/Home";
import { Gallery } from "./components/Gallery/Gallery";
import { Navbar } from "./components/Home/Header/Nav";
import { customColors } from "./theme";
import { Login } from "./components/AuthComponents/Login";
import { Register } from "./components/AuthComponents/Register";
import { Footer } from "./components/Footer/Footer";
import { PricingPlan } from "./components/Pricing/PricingPage";
import { MyPhotos } from "./components/Photos/MyPhotos";
import { Settings } from "./components/Settings/Settings";
import { Checkout } from "./components/Checkout/Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { TermsOfUse } from "./components/Legal/TermsOfUse";
import { PrivacyPolicy } from "./components/Legal/PrivacyPolicy";
import { ForgotPassword } from "./components/AuthComponents/ForgotPassword";
import { PhotoPage } from "./components/Photos/PhotoPage";
import { UpdatePaymentDetails } from "./components/Checkout/UpdatePaymentDetails";
import { useSubscriptionContext } from "./context/SubscriptionContext";
import { PaymentStatus } from "./components/Pricing/PaymentStatus";
import { PATHS } from "./routes/routes";
import ReactGA from "react-ga4";
import { SubscriptionType } from "./api/services/userSubscriptionService";
import { ConditionalRoute } from "./routes/ConditionalRoute";
import { CheckYourEmail } from "./components/AuthComponents/CheckYourEmail";
import { UploadPhoto } from "./components/Photos/UploadPhoto";
import { useAuthContext } from "./context/AuthContext";

const App: React.FC = () => {
  useEffect(() => {
    document.body.style.paddingTop = "env(safe-area-inset-top)";
    //document.body.style.backgroundColor = "#yourBackgroundColor"; // Replace with your desired color
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-4R3LCQ0T94"); // Replace with your Measurement ID
    ReactGA.send("pageview"); // Tracks the initial pageview
  }, []);

  const { isUserVerified } = useAuthContext();
  const { subscriptionResult } = useSubscriptionContext();

  const stripePromise = useMemo(() => {
    return loadStripe(
      "pk_test_51PyDmRRt2ts7ecPYv7dVbG52WrsSHcKtkWguAoYGefGJdWExUWH6tA6nUMmXV60cYVeozvcTXTiuYGrnjnKc50px00bTDsoCaL"
    );
  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: customColors.darkGray,
          }}
        >
          <Router>
            <Navbar />
            <Container
              disableGutters
              maxWidth={false}
              sx={{ flex: 1, padding: 0, margin: 0 }}
            >
              <Routes>
                {/* 🟢 Public Routes */}
                <Route path={PATHS.home} element={<Home />} />
                <Route path={PATHS.pricing} element={<PricingPlan />} />
                <Route path={PATHS.login} element={<Login />} />
                <Route path={PATHS.signup} element={<Register />} />
                <Route
                  path={PATHS.checkYourEmail}
                  element={<CheckYourEmail />}
                />
                <Route
                  path={PATHS.forgotPassword}
                  element={<ForgotPassword />}
                />
                <Route path={PATHS.uploadPhoto} element={<UploadPhoto />} />
                <Route path={PATHS.termsOfUse} element={<TermsOfUse />} />
                <Route path={PATHS.privacyPolicy} element={<PrivacyPolicy />} />
                <Route path={PATHS.checkout} element={<Checkout />} />
                <Route path={PATHS.photo} element={<PhotoPage />} />
                <Route
                  path={PATHS.updatePayment}
                  element={<UpdatePaymentDetails />}
                />
                <Route
                  path={`${PATHS.paymentStatus}/:status`}
                  element={<PaymentStatus />}
                />

                {/* 🔒 Conditional Routes */}

                {/* Allow My Photos for logged in users with cancelled or paid subscription */}
                <Route
                  element={
                    <ConditionalRoute
                      condition={
                        isUserVerified &&
                        (!!subscriptionResult?.canceled ||
                          subscriptionResult?.plan !== SubscriptionType.FREE)
                      }
                    />
                  }
                >
                  <Route path={PATHS.myPhotos} element={<MyPhotos />} />
                </Route>

                {/* Allow Settings for logged in users*/}
                <Route
                  element={<ConditionalRoute condition={isUserVerified} />}
                >
                  <Route path={PATHS.settings} element={<Settings />} />
                </Route>

                {/* Allow Gallery for all users except paid ones*/}
                <Route
                  element={
                    <ConditionalRoute
                      condition={
                        !isUserVerified ||
                        subscriptionResult?.plan === SubscriptionType.FREE
                      }
                    />
                  }
                >
                  <Route path={PATHS.gallery} element={<Gallery />} />
                </Route>

                {/* 🔄 Catch-all: Redirect unknown routes to Home */}
                <Route path="*" element={<Navigate to={PATHS.home} />} />
              </Routes>
            </Container>
          </Router>
          <Footer />
        </div>
      </Elements>
    </>
  );
};

export default App;
