import { ENDPOINTS } from "../endpoints";

export const cancelPayment = async (headers: {
  Authorization: string;
}): Promise<void> => {
  const endpoint = ENDPOINTS.CANCEL_SUBSCRIPTION;

  try {
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorMessage = `Failed to delete payment: ${response.status} ${response.statusText}`;
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error("Error deleting payment:", error);
    throw error;
  }
};
