import React, { CSSProperties, useEffect } from "react";
import { useParams } from "react-router-dom";
import { customColors } from "../../theme";
import { useTranslation } from "react-i18next";

type PaymentStatusParams = {
  status?: string;
};

export const PaymentStatus: React.FC = () => {
  const { status } = useParams<PaymentStatusParams>();

  const { t } = useTranslation();

  //Add Google event snippet only for success
  useEffect(() => {
    if (status === "success") {
      const script = document.createElement("script");
      script.innerHTML = `
          gtag('event', 'conversion_event_purchase', {});
        `;
      document.head.appendChild(script);
    }
  }, [status]);

  let currentStatus: string;

  switch (status) {
    case "success":
      currentStatus = "SUCCESS";
      break;
    case "subscription-upgraded":
      currentStatus = "UPGRADE";
      break;
    case "subscription-downgraded":
      currentStatus = "DOWNGRADE";
      break;
    case "cancel":
      currentStatus = "CANCEL";
      break;
    default:
      currentStatus = "FAIL";
      break;
  }

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px",
      minHeight: "500px",
    } as CSSProperties,
    box: {
      textAlign: "center" as CSSProperties["textAlign"],
      padding: "40px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "460px",
    } as CSSProperties,
    successBox: {
      backgroundColor: customColors.semiDarkGray,
    } as CSSProperties,
    errorBox: {
      backgroundColor: customColors.semiDarkGray,
    } as CSSProperties,
    heading: {
      margin: 0,
      fontSize: "2rem",
    } as CSSProperties,
    paragraph: {
      margin: "1rem 0",
      fontSize: "1rem",
    } as CSSProperties,
    button: {
      marginTop: "1.5rem",
      padding: "0.75rem 1.5rem",
      backgroundColor: customColors.gold,
      color: "#000",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "1rem",
      transition: "background-color 0.3s ease",
    } as CSSProperties,
    buttonHover: {
      backgroundColor: customColors.grayedGold,
    } as CSSProperties,
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.box,
          ...(currentStatus === "FAIL" ? styles.errorBox : styles.successBox),
        }}
      >
        <>
          <h1 style={styles.heading}>
            {t("TEXT.PAYMENT_STATUS." + currentStatus + "_TITLE")}
          </h1>
          <p style={styles.paragraph}>
            {t("TEXT.PAYMENT_STATUS." + currentStatus + "_TEXT")}
          </p>
        </>
        <button
          style={styles.button}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor =
              styles.buttonHover.backgroundColor || "")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = styles.button
              .backgroundColor as string)
          }
          onClick={() => (window.location.href = "/")}
        >
          {t("TEXT.PAYMENT_STATUS.ACTION_TEXT")}
        </button>
      </div>
    </div>
  );
};
