import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MailOutlineRounded } from "@mui/icons-material";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { useSubscriptionContext } from "../../context/SubscriptionContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useAuthContext } from "../../context/AuthContext";

export const CheckYourEmail: React.FC = () => {
  const { refetchSubscription } = useSubscriptionContext();
  const { refreshUser } = useAuthContext();
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isTooManyAttemptsError = (error: any) => {
    return (
      error?.message === "TOO_MANY_ATTEMPTS_TRY_LATER" ||
      error?.code === "auth/too-many-requests"
    );
  };

  const handleResendVerification = async () => {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        showSnackbar({
          message: t("TEXT.AUTH.VERIFICATION_EMAIL_SENT"),
          color: "success",
        });
      } catch (error: any) {
        if (isTooManyAttemptsError(error)) {
          showSnackbar({
            message: t("TEXT.AUTH.TOO_MANY_ATTEMPTS_TRY_LATER"),
            color: "error",
          });
        } else {
          showSnackbar({
            message: t("TEXT.AUTH.ERROR_OCCURRED"),
            color: "error",
          });
        }
      }
    }
  };

  const reloadUser = async () => {
    if (!auth.currentUser) {
      showSnackbar({
        message: t("TEXT.AUTH.USER_NOT_FOUND"),
        color: "error",
      });
      return;
    }

    try {
      // Refresh user state
      refreshUser();

      // Poll until user state is restored or a max timeout is reached (e.g., 5 seconds)
      const maxAttempts = 10;
      let attempt = 0;
      while (attempt < maxAttempts && !auth.currentUser.emailVerified) {
        await new Promise((resolve) => setTimeout(resolve, 500)); // Poll every 500ms
        attempt++;
      }

      // Check if email is verified
      if (auth.currentUser.emailVerified) {
        refetchSubscription();
        navigate("/");
      } else {
        showSnackbar({
          message: t("TEXT.AUTH.EMAIL_NOT_VERIFIED"),
          color: "warning",
        });
      }
    } catch (error: any) {
      if (isTooManyAttemptsError(error)) {
        showSnackbar({
          message: t("TEXT.AUTH.TOO_MANY_CHECKS"),
          color: "error",
        });
      } else {
        showSnackbar({
          message: t("TEXT.AUTH.VERIFICATION_CHECK_FAILED"),
          color: "error",
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: { xs: "20px", md: "60px", sx: "100px" },
      }}
    >
      <MailOutlineRounded color="primary" sx={{ fontSize: 80 }} />
      <Typography variant="h4" sx={{ mt: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_1")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 3, mb: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_2")}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1, mb: 2, textAlign: "center" }}>
        {t("TEXT.AUTH.CHECK_EMAIL_3")}
      </Typography>
      <Button
        onClick={reloadUser}
        variant="contained"
        color="primary"
        sx={{ mb: 1 }}
      >
        {t("TEXT.AUTH.I_VERIFIED_MY_EMAIL")}
      </Button>
      <Button onClick={handleResendVerification} variant="text">
        {t("TEXT.AUTH.RESEND_VERIFICATION_EMAIL")}
      </Button>
    </Box>
  );
};
