export const API_BASE_URL =
  "https://api-vs-us-dra5dvf0hwb3f9dg.eastus2-01.azurewebsites.net";

export const ENDPOINTS = {
  SPACES: `${API_BASE_URL}/spaces`,
  ORDER: `${API_BASE_URL}/order`,
  ORDER_STATUS: "/status",
  ORDER_GENERATE_MORE: `/generate_more`,
  GALLERY: `${API_BASE_URL}/gallery`,
  SUBSCRIPTION: `${API_BASE_URL}/user/subscription`,
  PAYMENT: `${API_BASE_URL}/payment`,
  CANCEL_SUBSCRIPTION: `${API_BASE_URL}/payment/cancel_subscription`,
  MANAGE_SUBSCRIPTION: `${API_BASE_URL}/payment/manage_subscription`,
  PAYMENT_HISTORY: `${API_BASE_URL}/payment/history`,
  NEWSLETTER: `${API_BASE_URL}/newsletter`,
  IMAGES: `/images`,
  DOWNLOAD: `/download`,
  PAYMENT_CUSTOMER_EMAIL: `${API_BASE_URL}/payment/session_customer_email`,
};
