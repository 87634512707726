import { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordFieldProps {
  password: string;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isPasswordValid: boolean;
  label: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  password,
  handlePasswordChange,
  isPasswordValid,
  label,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      label={label}
      type={showPassword ? "text" : "password"}
      value={password}
      onChange={handlePasswordChange}
      error={!isPasswordValid}
      helperText={!isPasswordValid ? "Weak password" : ""}
      autoComplete="new-password"
      sx={{
        input: { color: "white" }, // Text color inside the input
        label: { color: "white" }, // Label text color
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white", // Border color
          },
          "&:hover fieldset": {
            borderColor: "white", // Border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "white", // Border color when focused
          },
        },
      }}
      InputProps={{
        endAdornment:
          password.length > 0 ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? (
                  <VisibilityOff sx={{ color: "white" }} />
                ) : (
                  <Visibility sx={{ color: "white" }} />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
    />
  );
};

export default PasswordField;
