import { useSubscriptionContext } from "../../context/SubscriptionContext";
import {
  createOrder,
  PostOrderResponse,
} from "../../api/services/orderService";
import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../context/AuthContext";

interface OrderParams {
  image: File;
  removeFurniture: boolean;
  spaceName: string;
  styleId: string;
  widgetId: string;
  demoId: string | null;
}

export const useCreateOrderMutation = () => {
  const { token } = useAuthContext();
  const { refetchSubscription } = useSubscriptionContext();
  const mutation = useMutation<PostOrderResponse, Error, OrderParams>({
    mutationFn: async (params) => {
      if (!token) {
        throw new Error("Missing JWT token");
      }
      const headers = { Authorization: `Bearer ${token}` };
      return createOrder(params, headers);
    },
    onSuccess: () => refetchSubscription(),
  });

  return {
    handleCreateOrder: mutation.mutate,
    isOrderLoading: mutation.isPending,
    isOrderError: mutation.isError,
    isOrderSuccess: mutation.isSuccess,
    orderId: mutation.data?.order_id ?? null,
  };
};
